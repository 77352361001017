@import '../../variables.scss';

.carousel {
    @media (max-width: 700px){
        width: 110%;
        margin-left: -5%;
    }
}

.carousel-indicators {
    bottom: -85px;

     button{
        background-color: #2b48da!important;
        border-radius: 50% !important;
        height: 10px!important;
        width: 10px!important;
    }

    :not(.active){
        background-color: grey !important;
    }

    
}

.carousel-item {
    transition: transform 1.2s ease-in-out;

    img {
        min-height: 300px;
        max-height: 300px;
        object-fit: cover;
    }
}

.hidden {
    display: none;
}



.heroText {
    position: absolute;
    top: 55%;
    left: 50%;
    width: 700px;
    transform: translate(-50%, -50%);

    @media (max-width: 700px){
        width: 95%;
    }

    h2{
        font-size: 72px;
        line-height: 72px;
        font-weight: bold;
        text-align: center;


        @media (max-width: 700px){
            font-size: 57px;
            line-height: 60px;
        }

        @media (max-width: 450px){
            font-size: 50px;
            line-height: 60px;
        }

    }

    p {
        font-size: 36px;
        margin-top: 25px;
        text-align: center;

        @media (max-width: 700px){
            font-size: 33px;
            line-height: 45px;
        }

    }

    a{
        color: $textcolor;
    }


}