.contacts {
    display: flex;
    width: 100%;
    padding: 0 50px;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 150px;

    @media (max-width:1200px) {
        padding: 0px;
        margin-top: 100px;
        margin-bottom: -50px;
    }

    @media (max-width:900px) {
        flex-direction: column;
        margin-top: 50px;
    }

    @media (max-width:700px) {
        margin-top: 30px;
    }

    
    .block {
        margin: 0;
    }
    
    div:first-child {
        margin-right: 5%;

        @media (max-width:900px) {
            margin-right: 0%;
            margin-bottom: 25px;
        }
    }
}

