.block1, .block2 {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background: #f7f7f7;


    @media (max-width: 700px){
        width: 110%;
        margin-left: -5%;
    }

    // .text {
    //     white-space: pre-wrap;
    //     // test
    // }
   
}

.block1 { 

  
    @media (max-width: 900px){
        flex-direction: column-reverse;
        margin-top: 100px;
        justify-content: flex-start;
    }

    .text {
        padding: 80px 80px 80px 120px;
        text-align: left;
        box-sizing: border-box;
        line-height: 35px;
        font-size: 23px;
        font-weight: bold;
        width: 50%;

        @media (max-width: 1265px){
            padding: 50px 50px 50px 80px;
        }

        @media (max-width: 900px){
            width: 100%;
            text-align: center;
            padding: 50px 50px 50px 50px;
        }

        @media (max-width: 450px){
            padding: 50px 25px 50px 25px;
        }

        h2 {
            margin-bottom: 30px;
            font-size: 24pt;
            font-weight: bold;


            @media (max-width: 450px){
                margin-bottom: 20px;
            }
        }

        p{
            font-size: 14pt;

            @media (max-width: 450px){
                font-size: 12pt;
            }

        }
    }

}

.block2 {

 
    .text {
        padding: 80px 80px 80px 120px;
        text-align: left;
        box-sizing: border-box;
        line-height: 35px;
        font-size: 23px;
        font-weight: bold;
        width: 50%;

        @media (max-width: 1265px){
            padding: 50px 50px 50px 80px;
        }

        @media (max-width: 900px){
            width: 100%;
            text-align: center;
            padding: 50px 50px 50px 50px;
        }

        @media (max-width: 450px){
            padding: 50px 25px 50px 25px;
        }

            
    h2 {
        margin-bottom: 30px;
        font-size: 24pt;
        font-weight: bold;


        @media (max-width: 450px){
            margin-bottom: 20px;
        }
    }


        p{
            font-size: 14pt;

            @media (max-width: 450px){
                font-size: 12pt;
            }

        }
    }
       

    @media (max-width: 900px){
        flex-direction: column;
        margin-top: 50px;
        justify-content: flex-start;
    }
}


.block1 img {
    width: 50%;
    min-height: 470px;
    object-fit: cover;

    @media (max-width: 900px){
        width: 100%;
        margin-right: 0;
    }
}

.block2 img {
    width: 50%;
    min-height: 470px;
    object-fit: cover;

    @media (max-width: 900px){
        width: 100%;
        margin-left: 0;
    }
}
