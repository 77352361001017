@import '../../variables.scss';

.logout_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;


    @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        padding: 200px 0;
        margin: 100px 0;
    }


    .close-popup {
        position: absolute;
        top: 15px;
        right: 20px;
        font-weight: bold;
        font-size: 34px;
        cursor: pointer;
    }


    .card {
        border-radius: 10px;
        border: none;
        text-align: center;
        align-self: normal;
        padding: 0 40px;
        position: relative;


        @media (max-width: 900px) {
            margin-top: 50px;
            align-self: center;
        }
    
    
        .card-body{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
       
    
        .card-link {
            border-radius: 20px;
            padding: 5px 15px;
            width: 140px;
            text-decoration: none;
            background: #da7c2b;
            color: white;
            margin-left: 0;
        }
        
       
    }


}

form {
    background-color: white;
    padding: 40px 80px;
    border-radius: 10px;
    margin-right: 80px;
    text-align: center;
    position: relative;


    @media (max-width: 900px) {
        margin-right: 0px !important;
    }

    label {
        text-align: center;
        width: 100%;
    }

    .gudema {
        width: 100%;
        color: $color;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0;
    }   

    

    input[type="text"], input[type="password"]  {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 3px solid $color;
        border-radius: 0;
        text-align: center;
    }

    .form-control:focus {
        color: #212529;
        background-color: #fff;
        border-color: none;
        outline: 0;
        box-shadow: 0 0 0 0;
    }

    button[type="submit"] {
        border-radius: 20px;
        background: $color;
        padding: 3px 20px;
        margin: 0 auto;
    }
    
}

.fade-for-click-auth {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    // display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background: rgba($color: rgb(230, 230, 230), $alpha: 0.92);
}

