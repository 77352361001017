
  
  .trigger {
    bottom: -120px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    text-align: center;
    position: absolute;
    z-index: 1;
    // opacity: 0;
    
    .popup {
      display: none;
        position: absolute;
    left: 0;
    top: -69px;
    transform: translate3d(0, -50%, 0);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    background: none;
    border-radius: 100px;
    width: 100%;

    @media (max-width: 700px) {
      display: none;
    }
}

.popup:after {
    content: "";
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    background: #fff;
    position: absolute;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
    z-index: -1;
    top: -10px;
    left: calc(50% - 10px);
}

  .inner {
      padding: 10px 0;
      background: #fff;
      border-radius: 100px;
    }

    
}

.fade-in {
    animation: fadeIn ease 1s !important;
  }
  
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  
  .fade-out {
      animation: fadeOut ease 1s;
    }
    
    @keyframes fadeOut {
      0% {opacity:1;}
      100% {opacity:0;}
    }