@import '../../../variables';


    .fade-for-click2 {
        background: rgba($color: rgb(116, 116, 116), $alpha: 0.7);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1055;
        // display: none;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;


        @media (max-width: 600px) {
            display: none !important;
        }
    }



    .shopping-cart-popup {
        width: 60%;
        min-width: 400px;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: relative;

        @media (max-width: 990px) {
            width: 90%;
        }

       


     
        

        .close {
            position: absolute;
            top: 20px;
            right: 30px;
            font-size: 21px;
            font-weight: bold;
            z-index: 1;
            cursor: pointer;


            @media (max-width: 990px) {
                top: 10px;
                 right: 20px;
            }
        }
        
        .card {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: flex-start;
            padding: 8% 8% 5% 5%;
            border-radius: 15px !important;

            

        @media (max-width: 790px) {
            flex-direction: column;
            padding: 8% 5% 5% 5%;
            margin-top: 500px;
        }

        
            .card-title {
                font-size: 27px;
                text-align: left;
            }

            .card-body {
                justify-content: space-between;
                height: 100%;   
                
                
                @media (max-width: 768px) {
                    width: 100%;
                }
            }

            .card-subtitle {
                text-align: left;
                font-size: 17px;
            }

            .card-text {
                margin-bottom: 5px;

                span {
                    font-weight: bold;
                }

            }

            .colorParam {
                display: flex;

                span {
                    margin-right: 5px;
                }

            }


        }



        .promo {
            height: 100px;
             left: 7%;
             top: 15%;
             position: absolute;
             object-fit: contain;
             object-position: 0;

             @media (max-width: 768px) {
                 height: 150px;
                 top: 5%;
             }

         }

        
        img {
            width: 50%;

            @media (max-width: 790px) {
                width: 100%;
                margin-bottom: 10px;
            }
            
        }
        

        

            .title-wrap {
                
                div {
                    font-weight: bold;
                    font-size: 14px;

                      @media (max-width: 768px) {
                        font-size: 19px;
                    }
                }
            }

            .card-body {
                align-items: flex-start !important;
                padding: 0 0 0 2rem;

               

                > div p {
                    text-transform: uppercase;
                    font-size: 19px;
                    text-align: left;
                    color: black
                }
                
                p:first-child {
                    margin-bottom: 5px;

                    
                }
            }


            .countWrap {

                width: 60%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                > div {
                    width: 45%;

                    p {
                        font-size: 16px;
                        font-weight: bold;

                        @media (max-width: 768px) {
                            font-size: 17px;
                        }
                    }
                }



                .count {
                    margin-top: 15px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    user-select: none;

                    .minus, .plus{
                        background-color: $color;
                        border-radius: 50%;
                        font-weight: bold;
                        height: 18px;
                        width: 18px;
                        font-size: 12.6px;
                        color: white;
                        cursor: pointer;
                        text-align: center;

                        @media (max-width: 768px) {
                            height: 27px;
                            width: 27px;
                            font-size: 19px !important;
                        }
                    }


                    .minus:hover, .plus:hover {
                        background: $hover;
                    }

                    .minus {
                        font-size: 11.5px;

                        @media (max-width: 768px) {
                            font-size: 17px !important;
                        }
                    }

                    .amount {
                        font-weight: bold;
                    }
                }

                @media (max-width: 1550px) {
                    width: 100%;                    
                }





            }

            button {
                border-radius: 20px;
                margin-top: 20px;
                padding: 2px 15px;
                background-color: $color;
                font-size: 21px;

                @media (max-width: 768px) {
                    font-size: 24px;
                }

                &:focus {
                    box-shadow: none;
                }
            }

            .btn-primary:hover {
                background: $hover;
            }


        
    }



    // .card {
    //     border: none;
    //     text-align: center;
    //     padding: 0 30px;
    //     position: relative;
    
    //     .card-body{
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         align-items: center;
    //     }
       
    
        
    // }

    
    .card-group {
        @media (max-width: 576px){
            // display: flex;
        }
    }