.footer {
    margin: 100px 0 0px -50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    background: #f7f7f7;
    padding: 50px 0px 100px;
    width: calc(100% + 100px);

    .phoneMail {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 50%;


        @media (max-width: 700px){
            text-align: center;
            align-items: center;
        }


        .email {
            @media (max-width: 700px){
                margin-top: 30px !important;
                margin-bottom: 70px !important;
                line-height: 32px;
            }
        }

    }



    @media (max-width: 1150px){
        margin-top: 100px;
    }

    @media (max-width: 700px){
        // margin: 100px 0 50px;
        flex-direction: column;
        margin: 0px 0 0 -5%;
        width: 110%;
        padding: 70px 0 70px;
    }

    hr {
        position: absolute;
        top: -80px;
        // left: -50px;
        width: 100%;
    }

    .wrap {
        img {

            @media (max-width: 700px){
                width: 170px;
            }
        }
    }


    .wrap, .wrap2 {
        display: flex;       
        align-items: stretch;

        .requisites {
            max-width: 250px;
            font-size: 12pt;
            line-height: 32px;
            text-align: right;


            @media (max-width: 700px){
                text-align: center;
            }

            @media (max-width: 700px){
                margin: 0px auto;
            }
        }

    }

    div div p {
        margin-top: 50px;
        margin-bottom: 0;


        @media (max-width: 700px){
            margin-top: 10px;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
        font-size: 18pt;
    }

    h5, .h5 {
        white-space: nowrap;
        margin-bottom: 5px; 
    }

}

.footer .wrap {
    width: 100%;
    justify-content: space-between;

    div:nth-child(1) {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 100px;

        @media (max-width: 700px){
            margin-right: 0px;
            align-items: center;
        }

        @media (max-width: 700px){
            width: 100%;
            text-align: center;
        }
    
        .logo {
            margin-bottom: 20px;
        }

    }

    div:nth-child(2) {

        @media (max-width: 700px){
            width: 100%;
            margin-top: 25px;
        }

    }



    @media (max-width: 700px){
        flex-direction: column-reverse;
        width: 100%;
        align-items: center;
    }

}

.footer .wrap2 a {
    font-size: 14pt;
    margin-top: 5px;
}


.footer .wrap2 {
    width: 30%;
    justify-content: space-around;


    @media (max-width: 1150px){
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-end;
    }

    @media (max-width: 700px){
        display: none;
    }



}
