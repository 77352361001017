.box {
    width: 80%;
    margin: 150px auto 0;
    border-bottom: 6px solid #ebeefc;
    min-width: 300px;
    
  
    @media (max-width: 700px){
        width: 100%;
        margin: 100px auto 0;
    }
    
     td {
        font-size: 39px;
        position: relative;
        text-align: center;


      


        span {
            font-size: 39px;
            position: absolute;
            left: 50%;
            top: -15px;
            transform: translateX(-50%);

            @media (max-width: 1550px){
                font-size: 34px;
            }


            @media (max-width: 1350px){
                font-size: 29px;
            }

            @media (max-width: 1150px){
                font-size: 27px;
                top: 10px;
            }

            @media (max-width: 768px){
                font-size: 21px;
                top: 15px;
            }
            @media (max-width: 450px){
                font-size: 19px;
                top: 15px;
            }

        }

        img:nth-child(2) {
            width: 12%;
            margin-bottom: -62px;
            max-width: 50px;

            @media (max-width: 1150px){
                width: 30px;
            }

        }
        
        img:last-child {
            position: absolute;
            bottom: -30%;
            left: -10%;
            width: 70px;

            @media (max-width: 1350px){
                width: 50px;
            }

            @media (max-width: 1150px){
                width: 30px;
                left: -10%;
                bottom: -20%;
            }

        }
        
                &:first-child img:last-child {
                    left: 13%;
                    width: 40px;


                    @media (max-width: 1350px){
                        width: 28px;
                    }

                    @media (max-width: 1150px){
                        width: 15px;
                    }
                }

                &:last-child img:last-child {
                    bottom: -20%;
                }

    }

    
}

.text{
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    margin: 50px auto 180px;

    @media (max-width: 1150px){
        font-size: 27px;
    }

    @media (max-width: 700px){
        font-size: 16pt;
        margin: 50px auto 130px;
    }
}