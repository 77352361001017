.pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    margin-bottom: -50px;

    @media (max-width: 900px) {
        margin-bottom: 100px;
        flex-direction: column;
    }


    .active {
        font-weight: bold;
    }

    a {
        color: black;
        text-decoration: none;
        margin-right: 10px;
        padding: 5px;
    }

    .filter-pagin {
        margin-left: -370px;
        margin-right: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 900px) {
           margin-top: 50px;
        }

        span {
            white-space: nowrap;
        }

        @media (max-width: 1170px) {
            margin-left: 0px;
            margin-right: 0px;
        }

    }


    select {
        border: none;
        

        &:focus {
            border-color: white;
            box-shadow: none;
            color: black
        }
    }

    .pageNum {
        list-style: none;
        margin-bottom: 0;

        li {
            display: inline-block;
            border: 1px solid rgb(219, 219, 219);

            a {
                margin:  0 5px;
            }
        }
    }

}