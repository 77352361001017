@import '../../../variables';

.shopingCard-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    


    .shopping-cart {
        width: 19%;
        min-width: 235px;
        margin-bottom: 20px;
        margin-left: 2px;
        margin-right: 2px;
        margin-top: 2px;
        box-shadow: 1px 1px 3px #959595;
        
        

        @media (max-width: 600px) {
            min-width: 300px;            
            width: 90%;
            box-shadow: none
            // margin: 0 auto
            // max-width: 100%;
        }

        
            .promo {
               height: 70px;
                left: 10%;
                top: 70px;
                position: absolute;
                object-fit: contain;
                object-position: 0;

                @media (max-width: 600px) {
                    height: 100px;
                    top: 20px;
                }

            }

            img {
                height: 200px;
                object-fit: contain;

                @media (max-width: 600px) {
                    object-fit: cover;
                    margin-top: 10px
                }
            }
        

            .title-wrap {
                padding: 0 5px;
                position: relative;

                @media (max-width: 600px) {
                    padding: 0px;
                }
                
                div {
                    font-weight: bold;
                    font-size: 14px;

                    @media (max-width: 600px) {
                        font-size: 18px;
                    }

                }
                
                div:first-child {
                    position: absolute;
                    bottom: 0;
                    width: 75%;
                    text-align: left;

                    @media (max-width: 600px) {
                        // width: 100%;
                    }

                }
                div:last-child {
                    float: right;
                }
            }

            .card-body {
                align-items: flex-start !important;
                cursor: default;

                p {
                    margin-bottom: 0px;
                }

                p:first-child {
                    font-size: 13px;

                    @media (max-width: 600px) {
                        font-size: 19px;
                    }
                }

                > p:not(:first-child) {
                    
                    text-transform: uppercase;
                    font-size: 15px;
                    text-align: left;

                    span {
                        font-weight: bold;
                    }

                    @media (max-width: 600px) {
                        font-size: 16px;
                    }
                }
                
                p:first-child {
                    margin-bottom: 5px;
                }

                .colorParam {
                    height: 40px;
                    display: flex;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media (max-width: 600px) {
                        height: 45px;
                    }

                    span {
                        margin-right: 5px;
                    }
                }

            }


            .countWrap {

                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                > div {
                    width: 45%;

                    p {
                        font-size: 16px;
                        font-weight: bold;


                        @media (max-width: 600px) {
                            font-size: 17px;
                        }
                    }
                }



                .count {
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    user-select: none;

                    .minus, .plus{
                        background-color: $color;
                        border-radius: 50%;
                        font-weight: bold;
                        height: 18px;
                        width: 18px;
                        font-size: 12.6px;
                        color: white;
                        cursor: pointer;

                        @media (max-width: 600px) {
                            height: 27px;
                            width: 27px;
                            font-size: 19px !important;
                        }
                    }

                    .minus:hover, .plus:hover{
                        background-color: $hover;
                    }

                    .minus {
                        font-size: 11.5px;

                        @media (max-width: 600px) {
                            font-size: 17px !important;
                        }
                    }

                    .amount {
                        font-weight: bold;

                        @media (max-width: 600px) {
                            font-size: 24px;
                        }
                    }
                }
            }


            .bottom{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                img {
                    width: 30px;
                }
                
                button {
                    border-radius: 20px;
                    margin-top: 20px;
                    padding: 2px 15px;
                    background-color: $color;

                    @media (max-width: 600px) {
                        font-size: 19px;
                    }
                    
                    
                    &:focus {
                        box-shadow: none;
                    }
                }
                
                button:hover {
                  background: $hover;
                }

                
                
            }


        
    }



    .card {
        border: none;
        text-align: center;
        padding: 30px 10px 0;
        position: relative;
        cursor: pointer;
        


        @media (max-width: 600px){
            padding: 0 5px 20px;
            box-shadow: none;
            border-bottom: 1px solid #c8c9ca;
            border-radius: 0;
            margin-bottom: 50px;
        }

    
        .card-body{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            .text_supplier {
                text-align: left;
                height: 40px;
                overflow: hidden;

                @media (max-width: 600px){
                    height: auto;
                }

            }
            
        }
    
        
    }

    
}

.disabled {
    opacity: 0.7;
}