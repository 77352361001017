@import '../../variables.scss';

.popup_wrap_mobile {
    text-align: center;


    .langs_m {
        position: relative; 
        width: 100px; 
        display: none;
        text-transform: uppercase;
        
        a {
            font-size: 21px;
        }

        @media (max-width: 900px) {
            display: inline-block;
        }
    }

    ul {
        list-style: none;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        top: 30%;


        li {
            margin-bottom: 10px;
        }

        a {
            text-decoration: none;
            color: #000;
            font-size: 21px;
            cursor: pointer;
        }

    }
 

        .close-popup {
            position: absolute;
            top: 15px;
            right: 50px;
            font-weight: bold;
            font-size: 34px;
            cursor: pointer;


            

        }

        .active {
            text-decoration: underline;
        }


        li ul {
            list-style: none;
            position: absolute;
            padding-left: 0;
            // left: -10px;
            top: 35px;
            z-index: 100;
            height: 0;
            overflow: hidden;
            

            li {
                margin-bottom: 10px;
            }

            

        }

        .slideOut {
            height: auto !important;
        }


        .arrow {
            position: absolute;
            top: 1px;
            right: 16px;
            font-size: 20px;
            transform: rotate(-90deg);
            font-weight: bold;
        }
        
        .rotated {
            transform: rotate(90deg);
            right: 14px;
        }

}    
    .fade-for-click {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1055;
        // display: none;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
        background: rgba($color: #fff, $alpha: 0.92);
    }

    