@import '../../../variables';


.Cart-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 80px 90px 50px;

    @media(max-width: 900px){
        flex-direction: column;
        align-items: flex-start;
    }

    @media(max-width: 768px){
        padding: 80px 0 50px;
    }

    @media(max-width: 700px){
        padding: 40px 0 50px;
    }

    .save {
        align-self: flex-end;
        padding: 2px 8px;
        width: 88.56px;
    }

    textarea{
        // border-radius: 8px;
        margin-right: 20px;
        height: 120px;
        width: 300px;
        align-self: flex-end;
        padding: 5px; 
        // box-sizing: content-box;
        border: none;
        border-bottom: 1px solid;
        overflow: hidden;
        padding-top: 95px;
        font-size: 13px;
    }

    .textarea {
        margin-right: 20px;
        height: 120px;
        width: 300px;
        align-self: flex-end;
        padding: 5px; 
        display: flex;
        align-items: flex-end;
        
        font-size: 13px;
    }

    textarea:focus, textarea:active, textarea:focus-visible {
        outline: none;
    }

    .comment_button {
        margin-top: 20px;
        margin-right: 10px;
        background: $color;
        border-radius: 15px;
        border: none;
        padding: 2px 9px 1px;
        color: #fff;
        white-space: nowrap;
        cursor: pointer;
        align-self: flex-end;
        cursor: pointer;
        width: 150px;
    }

    .buttons_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

   
    }

    .close {
        font-weight: bold;
        background: $color;
        border-radius: 50%;
        padding: 2px 9px 1px;
        color: #fff;
        cursor: pointer;

        @media (max-width: 600px) {
            // padding: 6px 14px 5px;
            // font-size: 21px;
        }
    }


    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ul{
            margin-bottom: 0 !important;
            list-style: none;

            @media(max-width: 768px){
                padding-left: 0;
            }
            

            span {
                font-weight: bold;
            }
            
            .colorParam {
                display: flex;

                span {
                    margin-right: 5px;
                }

            }
            
        }

        ul:last-child {
            @media(max-width: 768px){
               margin-top: 20px;
            }
        }
        
        ul:last-child li {
              @media(max-width: 768px){
                //   font-size: 25px;
              }
          } 


        ul:first-child {
            text-transform: uppercase;

            
        }

        ul:first-child li:first-child {
            text-transform: uppercase;
            font-size: 21px;
            font-weight: bold;

             @media (max-width: 600px) {
                font-size: 23px;
            }
        }



    }


    .wrap {
        display: flex;
        width: 100%;

        @media(max-width: 768px){
            flex-direction: column;
        }

    }


    .image {
        max-width: 270px;
        display: flex;
        align-items: flex-start;
        position: relative;


        @media(max-width: 768px){
            max-width: 100%;
            margin-bottom: 20px;
        }

        img {
            width: 100%;
        }

        .promo {
            height: 70px;
             left: 10%;
             top: 0px;
             position: absolute;
             object-fit: contain;
             object-position: 0;

             @media (max-width: 600px) {
                 height: 100px;
                 top: 20px;
             }

         }

    }

    .controls-wrap {
        width: 25%;
        max-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end; 

        @media(max-width: 900px){
            width: 100%;
            max-width: 100%;
        }

        .controls {
            // background: grey;

            

            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            > div {
                width: 100px;
                text-align: center;

                @media (max-width: 600px) {
                    width: 110px;
                }

                p {
                    font-size: 16px;
                    font-weight: bold;
                }
            }



            .count {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                user-select: none;

                .minus, .plus{
                    background-color: $color;
                    border-radius: 50%;
                    font-weight: bold;
                    height: 18px;
                    width: 18px;
                    font-size: 12.6px;
                    color: white;
                    cursor: pointer;
                    text-align: center;

                    @media (max-width: 600px) {
                        // height: 27px;
                        // width: 27px;
                        // font-size: 19px !important;
                    }
                }
                
                .minus:hover, .plus:hover{
                    background-color: $hover;
                }

                .minus {
                    // font-size: 11.5px;

                    @media (max-width: 600px) {
                        // font-size: 17px !important;
                    }
                }

                .amount {
                    font-weight: bold;

                    @media (max-width: 600px) {
                        // font-size: 24px;
                    }
                }
            }
        }
}


}

.hr {
    width: calc(100% - 180px);
    margin: 0 auto;
    background-color: #5c5c5c;
    opacity: 1;
    padding: 1px 0;


    @media(max-width: 768px){
        width: 90%;
    }
}