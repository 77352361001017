@import '../../../variables';


.conf-cart {
    margin-top: 100px;
    margin-bottom: -50px;
    text-align: center;
    position: relative;
    
    @media (max-width: 900px) {
            margin-bottom: 0;
    }

    button {
        background-color: $color;
        color: white;
        padding: 10px 15px;
        border-radius: 30px;
        max-width: 150px;
        line-height: 1.2;
    }

    p {
        max-width: 400px;
        margin: 30px auto 0;
    }

    hr {
        position: absolute;
        width: calc(100% + 100px);
        bottom: -70px;
        left: -50px;


    }

}